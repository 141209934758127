















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class Advert2 extends Vue {
  mounted () {
    // this.$store.commit()
    let content = document.querySelector('.advert5-content')
    let close = document.querySelector('.advert5-close')
    let closeRe = document.querySelector('.advert5-re-c')
    let contentRe = document.querySelector('.advert5-red-envelopes')
    // let data = this.$store.state.advert2.query
    // console.log(this.$store.state)
    // if (data && data.click && content) { // 广告点击关闭后就不再显示
    //   content.classList.add('close')
    // }
    if (closeRe) {
      closeRe.addEventListener('click', () => {
        if (contentRe) {
          contentRe.classList.remove('show-content')
          contentRe.classList.add('close-content')
        }
        document.body.style.overflow = 'auto'
      })
    }
    if (content) {
      content.addEventListener('click', () => {
        if (contentRe) {
          contentRe.classList.remove('close-content')
          contentRe.classList.add('show-content')
        }
        document.body.style.overflow = 'hidden'
      })
    }
    if (close) {
      close.addEventListener('click',(e) => {
        e.stopPropagation()
        if (content) {
          content.classList.add('close-content')
          // this.$store.commit('advert2/setQuery', { click: true })
        }
      })
    }
  }
}
