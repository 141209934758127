





































































































































































































































































import { Component, Vue, Inject } from 'vue-property-decorator'
import Advert from '@/components/Advert/Advert.vue'
import Advert2 from '@/components/Advert/Advert2.vue'
import Advert3 from '@/components/Advert/Advert3.vue'
import Advert4 from '@/components/Advert/Advert4.vue'
import Advert5 from '@/components/Advert/Advert5.vue'
import Advert6 from '@/components/Advert/Advert6.vue'

@Component({ components: { Advert, Advert2,Advert3,Advert4, Advert5, Advert6 } })
export default class Download extends Vue {
  loaded: boolean = false // 页面是否已经加载完成
  downloadStatus = {
    shansuan: false,
    teacher: false,
    siwei: false,
    JinJuTeacher: false,
    xueyuan: false
  }
  to (path: string) {
    Lib.router.push(path)
  }
  hideDownload (appName: string) {
    if (appName === 'shansuan') {
      this.downloadStatus.shansuan = false
    } else if (appName === 'teacher') {
      this.downloadStatus.teacher = false
    } else if (appName === 'siwei') {
      this.downloadStatus.siwei = false
    } else if (appName === 'JinJuTeacher') {
      this.downloadStatus.JinJuTeacher = false
    } else if (appName === 'xueyuan') {
      this.downloadStatus.xueyuan = false
    }
  }
  showDownload (appName: string) {
    if (appName === 'shansuan') {
      this.downloadStatus.shansuan = true
    } else if (appName === 'teacher') {
      this.downloadStatus.teacher = true
    } else if (appName === 'siwei') {
      this.downloadStatus.siwei = true
    } else if (appName === 'JinJuTeacher') {
      this.downloadStatus.JinJuTeacher = true
    } else if (appName === 'xueyuan') {
      this.downloadStatus.xueyuan = true
    }
  }
  created () {
    this.loaded = true
  }
  mounted () {
    // 兼容prerender-spa-plugin
    Lib.dispatchEvent(document, 'custom-render-trigger')
    let downloadShansuan = document.querySelector('.product-item-download-shansuan')
    let btnCloseShansuan = document.querySelector('.product-item-close-shansuan')
    let btnDownloadShansuan = document.querySelector('.product-btn-download-shansuan')
    let downloadTeacher = document.querySelector('.product-item-download-teacher')
    let btnCloseTeacher = document.querySelector('.product-item-close-teacher')
    let btnDownloadTeacher = document.querySelector('.product-btn-download-teacher')
    let downloadSiwei = document.querySelector('.product-item-download-siwei')
    let btnCloseSiwei = document.querySelector('.product-item-close-siwei')
    let btnDownloadSiwei = document.querySelector('.product-btn-download-siwei')
    let downloadXueyuan = document.querySelector('.product-item-download-xueyuan')
    let btnCloseXueyuan = document.querySelector('.product-item-close-xueyuan')
    let btnDownloadXueyuan = document.querySelector('.product-btn-download-xueyuan')
    if (btnDownloadShansuan && downloadShansuan) {
      btnDownloadShansuan.addEventListener('click', () => {
        downloadShansuan!.classList.add('show')
      })
    }
    if (btnCloseShansuan && downloadShansuan) {
      btnCloseShansuan.addEventListener('click', () => {
        downloadShansuan!.classList.remove('show')
      })
    }
    if (btnDownloadTeacher && downloadTeacher) {
      btnDownloadTeacher.addEventListener('click', () => {
        downloadTeacher!.classList.add('show')
      })
    }
    if (btnCloseTeacher && downloadTeacher) {
      btnCloseTeacher.addEventListener('click', () => {
        downloadTeacher!.classList.remove('show')
      })
    }
    if (btnDownloadSiwei && downloadSiwei) {
      btnDownloadSiwei.addEventListener('click', () => {
        downloadSiwei!.classList.add('show')
      })
    }
    if (btnCloseSiwei && downloadSiwei) {
      btnCloseSiwei.addEventListener('click', () => {
        downloadSiwei!.classList.remove('show')
      })
    }
    if (btnDownloadXueyuan && downloadXueyuan) {
      btnDownloadXueyuan.addEventListener('click', () => {
        downloadXueyuan!.classList.add('show')
      })
    }
    if (btnCloseXueyuan && downloadXueyuan) {
      btnCloseXueyuan.addEventListener('click', () => {
        downloadXueyuan!.classList.remove('show')
      })
    }
  }
}
